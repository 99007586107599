import * as React from 'react';
// import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import copy from 'copy-to-clipboard';

export function Fence({ children, language }) {
    const [copied, setCopied] = React.useState(false);
    const ref = React.useRef(null);

    // React.useLayoutEffect(() => {
    //     if (ref.current) Prism.highlightElement(ref.current, false);
    // }, [children]);

    React.useEffect(() => {
        if (copied) {
            copy(ref.current.innerText);
            const to = setTimeout(setCopied, 1000, false);
            return () => clearTimeout(to);
        }
    }, [copied]);

    // const lines = typeof children === 'string' ? children.split('\n').filter(Boolean) : [];

    return (
        <pre aria-live="polite" className={`language-${language} line-numbers`}>
            <code
                // Prevents "Failed to execute 'removeChild' on 'Node'" error
                // https://stackoverflow.com/questions/54880669/react-domexception-failed-to-execute-removechild-on-node-the-node-to-be-re
                key={children}
                ref={ref}
                className={`language-${language} line-numbers`}
            >
                {children}
            </code>
            <button onClick={() => setCopied(true)}>
                <span className={copied ? "fe fe-clipboard" : "fe fe-copy"} />
            </button>
        </pre>
    );
}

export const fence = {
    render: 'Fence',
    attributes: {
        language: {
            type: String,
            description:
                'The programming language of the code block. Place it after the backticks.'
        }
    }
};
