import React, { useState } from "react"
import { Link } from "gatsby";
import { Collapse } from "react-bootstrap";
import _ from 'underscore';

function SidebarNav({ product_group, location, setOpen, open }) {
    let groups = _.groupBy(product_group, function(o) {
        return o.childMdx.frontmatter.group;
    })

    return (
        <div className="d-lg-flex col-12 col-lg-3 col-xl-2 px-lg-0 pt-lg-12 pt-7 mt-lg-5 border-end-lg border-gray-300 sidenav sidenav-start">
            <Collapse className="d-lg-block" id="sidenavCollapse" in={open}>
                <div className="py-7 py-lg-7 px-lg-5">
                    {Object.keys(groups).map((groupname, i) => {
                        let group = groups[groupname];
                        return <div key={groupname} className="mt-2">
                            {groupname !== 'index' && Boolean(groupname) && groupname !== "null" ? <h6 className="text-uppercase fw-bold mt-4 mb-1">{groupname}</h6> : ""}
                            <ul className="list">
                                {group.map(node => {
                                    return <li key={node.childMdx.slug} className="list-item">
                                        <Link to={'/' + node.childMdx.slug} onClick={() => setOpen(false)} href="#" className={('/' + node.childMdx.slug === location.pathname || '/' + node.childMdx.slug + '/' === location.pathname ? "active " : "") + "list-link"}>
                                            {node.childMdx.frontmatter.nav_title}
                                        </Link>
                                    </li>
                                })}
                            </ul>
                        </div>
                    })}
                </div>
            </Collapse>
        </div>
    )
}

export default SidebarNav