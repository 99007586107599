import * as React from 'react';

export function ClickableCard({ children, title, subtitle, href }) {
    return (
        <div className="card bg-light">
            <a className="card-body my-auto" href={href}>
                <h3 className="text-black">{title}</h3>
                <p className="mb-0 text-dark">{subtitle}</p>
                {children}
            </a>
        </div>
    )
}