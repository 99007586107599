import {Tag} from "@markdoc/markdoc";

export const button = {
    render: 'Button',
    description: 'Display a button with a specific style and format',
    children: ['inline'],
    attributes: {
        type: {
            type: String,
            matches: ['outline', 'block'],
            description:
                'Controls the color and shape of the button. Can be: "outline", "block"'
        },
        color: {
            type: String,
            default: 'link',
            matches: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link'],
            description:
                'Controls the color and shape of the button. Can be: "primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "link"'
        },
        size: {
            type: String,
            matches: ['small', 'large'],
            description:
                'Controls the size of the button. Can be: "small", "default", "large"'
        },
        href: {
            type: String,
            description: 'The button link destination'
        },
        arrow: {
            type: Boolean,
            description: 'Whether to include an arrow at the end of the Button text'
        }
    }
};