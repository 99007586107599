import * as React from 'react';
import copy from 'copy-to-clipboard';

export function Heading({ id = '', level = 1, children, className }) {
    const Component = `h${level}`;
    const [copied, setCopied] = React.useState(false);
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (copied && Boolean(window?.location)) {
            copy(`${window.location.origin}${window.location.pathname}#${id}`)
            const to = setTimeout(setCopied, 1000, false);
            return () => clearTimeout(to);
        }
    }, [copied]);

    const link = (
        <Component className={['heading', className].filter(Boolean).join(' ')}>
            <div id={id} />
            {children}
        </Component>
    );

    return (
        <a href={`#${id}`} className={copied ? "heading-link copied" : "heading-link"} onClick={() => setCopied(true)}>
            {link}
        </a>
    ) ;
}