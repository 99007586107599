import React from 'react';
import {TabContext} from "../../context/tabs";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

function MarkdocTabs({ labels, children }) {
    return (
        <TabContext.Consumer>
            {({app_interface, setInterface}) => {
                return (
                    <Tabs
                        className="interface-tabs"
                        activeKey={app_interface}
                        onSelect={(k) => setInterface(k)}
                        variant="pills"
                    >
                        {labels.map((label) => (
                            <Tab eventKey={label} title={label} key={label} className="mb-5">
                                {children}
                            </Tab>
                        ))}
                    </Tabs>
                )}
            }
        </TabContext.Consumer>
    );
}

export default MarkdocTabs