export const row = {
    render: 'Row',
    description: 'Set a Row container to hold Columns',
    children: ['inline'],
    attributes: {
        justify: {
            type: String,
            description:
                'Controls how content is justified. Valid values are "start" (default), "center", or "end".'
        },
    }
};