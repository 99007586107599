import * as React from 'react';

export function Button({ children, type, color, size, href, arrow }) {
    const typeClass = Boolean(type) ? "btn-"+type : "";
    const colorClass = Boolean(color) ? "btn-"+color : "";
    const sizeClass = size === 'small' ? "btn-sm" : size === 'large' ? "btn-lg" : "";

    return (
        <>
            <br />
            <a href={href} className={"btn text-decoration-none " + typeClass + " " + colorClass + " " + sizeClass}>
                {children} {arrow ? <i className="fe fe-arrow-right ms-1" /> : ""}
            </a>
        </>
    )
}