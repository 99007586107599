export const column = {
    render: 'Column',
    description: 'Set a Column container to hold content in a column',
    children: ['inline'],
    attributes: {
        size: {
            type: String,
            description:
                'Controls how wide the content container is. Valid values are string numbers 1-12. Leave blank for automatic width.'
        },
    }
};