import React, { useState } from "react"

function DocsTocNav({ toc }) {

    return (
        <div className="col-12 col-lg-3 col-xl-2 pt-lg-11 mt-lg-5 d-none d-lg-block px-lg-0 sidenav sidenav-end">
            <div className="my-7 my-lg-9 px-lg-7 border-start-lg border-gray-300">
                <div className="toc">
                    <h6 className={Boolean(toc.items) && toc.items.length > 0 ? "text-uppercase d-none d-sm-block text-muted" : "d-none"}>On This Page</h6>

                    {Boolean(toc.items) && toc.items.length > 0 ? toc.items.map(item => {
                        return <ul className="list mb-2" key={item.url}>
                            <li className="list-item">
                                <a href={item.url} className="list-link">{item.title}</a>
                            </li>
                            {Boolean(item.items) ? item.items.map(subitem => {
                                return <li key={subitem.url+subitem.title} className="list-item">
                                    <a href={subitem.url} className="sublink list-link">{subitem.title}</a>
                                </li>
                            }) : ""}
                        </ul>
                    }) : ""}
                </div>
            </div>
        </div>

    )
}

export default DocsTocNav