import * as React from 'react';

export function Column({ children, size }) {
    const colClass = Boolean(size) ? "col-12 col-lg-"+size : "col-12 col-lg"

    return (
        <div className={colClass}>
            {children}
        </div>
    )
}