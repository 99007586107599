import React from "react"
import { Link } from "gatsby";
import Markdoc from '@markdoc/markdoc';
import {Fence, fence} from "../markdoc/components/Code";
import heading from '../markdoc/nodes/heading.markdoc'
import {Heading} from "../markdoc/components/Heading"
import { nodes } from '@markdoc/markdoc';
import { Table } from '../markdoc/components/Table'
import { button } from '../markdoc/nodes/button.markdoc'
import { Button } from '../markdoc/components/Button'
import {tab, tabs} from '../markdoc/nodes/tabs.markdoc'
import {Tab} from '../markdoc/components/Tab'
import Tabs from '../markdoc/components/Tabs'
import {TabContext} from "../context/tabs"
import { card } from '../markdoc/nodes/card.markdoc'
import { ClickableCard } from '../markdoc/components/ClickableCard'
import { row } from '../markdoc/nodes/row.markdoc'
import { Row } from '../markdoc/components/Row'
import { column } from '../markdoc/nodes/column.markdoc'
import { Column } from '../markdoc/components/Column'

function DocsContent({ frontmatter, nextnode, children, content, title }) {

    const [
        app_interface,
        setInterface
    ] = React.useState('UI');

    // const button = '{% button href="#" size="small" color="primary" %} Go Do this {% /button %}'

    // const tabs = '{% tabs %} ' +
    //     '{% tab label="UI" %}ui content goes here{% /tab %} ' +
    //     '{% tab label="API" %}api content goes here{% /tab %}' +
    //     '{% /tabs %}' +
    //     '{% tabs %} ' +
    //     '{% tab label="UI" %}ui 2 content goes here{% /tab %} ' +
    //     '{% tab label="API" %}api 2 content goes here{% /tab %}' +
    //     '{% /tabs %}'

    // const markdown = '# This is a headline \n ' +
    //     '{% row %}\n' +
    //     '{% column %}\n' +
    //     '{% card title="Solutions" href="/solutions" %}\n' +
    //     '- weqwewrerasdfasdf See all Firstparty solutions\n' +
    //     '- weqwewrerasdfasdf See all Firstparty solutions\n' +
    //     '{% /card %}\n' +
    //     '{% /column %}\n' +
    //     '{% column %}\n' +
    //     '{% card title="Solutions" href="/solutions" %}\n' +
    //     'See all Firstparty solutions\n' +
    //     '{% /card %}\n' +
    //     '{% /column %}\n' +
    //     '{% /row %}';

    const config = {
        transformer: {
            tags: {
                button,
                card,
                column,
                row,
                tabs,
                tab,
            },
            nodes: {
                fence,
                heading,
                table: {
                    ...nodes.table,
                    render: 'Table' // your custom component goes here
                }
            }
        },
        renderer: {
            components: {
                Button,
                ClickableCard,
                Column,
                Fence,
                Heading,
                Row,
                Table,
                Tab,
                Tabs,
            }
        }
    }

    const ast = Markdoc.parse(content);
    const transformed = Markdoc.transform(ast, config.transformer);
    const rendered = Markdoc.renderers.react(transformed, React, config.renderer)

    return (
        <div className="documentation-content col-12 col-lg-6 col-xl-8 offset-lg-3 offset-xl-2 py-8 py-lg-10 px-lg-7 mt-lg-5">
            <div className="row h-100 d-flex flex-row">
                <div className="col-12 align-self-start">
                    <h1 className="fw-light text-muted fs-1">{frontmatter.title || title}</h1>
                    <p className="lead mb-0">{frontmatter.lead}</p>
                    <hr className="my-6 my-md-5 border-gray-300"/>
                    <TabContext.Provider value={{app_interface, setInterface}}>
                        {rendered}
                    </TabContext.Provider>
                </div>
                <div className="col-12 align-self-end d-flex flex-row align-items-end w-100 pb-5">
                    <div className="w-100">
                        <hr />
                        {
                            Boolean(nextnode) ?
                                <Link to={'/docs/' + nextnode.childMdx.slug} className="float-end next-link">Next: {nextnode.childMdx.frontmatter.title} →</Link>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DocsContent