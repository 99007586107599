import { Tag } from '@markdoc/markdoc';

export const tabs = {
    render: 'Tabs',
    attributes: {},
    transform(node, config) {
        const labels = node
            .transformChildren(config)
            .filter((child) => child && child.name === 'Tab')
            .map((tab) => (typeof tab === 'object' ? tab.attributes.label : null));

        const children = node.transformChildren(config);

        return new Tag(this.render, { labels }, children);
    }
};

export const tab = {
    render: 'Tab',
    attributes: {
        label: {
            type: String,
        }
    }
};