import React from 'react';
import {TabContext} from "../../context/tabs";

export const Tab = ({ label, children }) => {
    const currentTab = React.useContext(TabContext);

    if (label !== currentTab.app_interface) {
        return null;
    }

    return children;
};