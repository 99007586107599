import React, { useState } from "react"
import Container from "react-bootstrap/Container";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { Button, Collapse } from "react-bootstrap";
import DocsHead from "./DocsHead";
import SidebarNav from "./docs/sidebar-nav";
import DocsContent from "./docs/content";
import DocsTocNav from "./docs/toc-nav";
import logo from "../images/firstparty-logo-mark.svg";

function DocsLayout({ children, location, title = null, pageContext }) {
    const [open, setOpen] = useState(false);
    const data = useStaticQuery(graphql
        `query DocsQuery {
  allFile(
    filter: {childMdx: {slug: {regex: "docs/"}}}
    sort: {fields: childMdx___frontmatter___order, order: ASC}
  ) {
    group(field: childMdx___frontmatter___product) {
      fieldValue
      field
      nodes {
        childMdx {
          slug
          id
          frontmatter {
            date
            group
            lead
            nav_title
            order
            product
            title
          }
          tableOfContents
        }
      }
    }
  }
}
`
    )

    let product_slugs = {
        analytics: 'Analytics',
        audiences: 'Audiences',
        automation: 'Automation',
        account: 'Account',
        sdk: 'SDK',
    }

    let product = location.pathname.split('/')[2];
    let product_name = product_slugs[product];

    let toc = pageContext.childMdx.tableOfContents;
    let frontmatter = pageContext.childMdx.frontmatter;
    let body = pageContext.childMdx.rawBody;
    let product_group = [];

    data.allFile.group.forEach(productGroup => {
        if(productGroup.fieldValue === product_name) {
            product_group = productGroup.nodes;
        }
    })

    frontmatter.pageTitle = "Firstparty - " + frontmatter.title || title;

    return (
        <div>
            <Helmet>
                <title>{frontmatter.pageTitle}</title>
                <link rel="icon" href={logo} />
                <body className="docs header-offset" />
            </Helmet>
            <DocsHead fluid={true} fixed={true} location={location} />
            <nav className="bg-dark fixed-top fixed-top-sub">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <span className="text-white">
                                        Docs
                                    </span>
                                </li>
                                {Boolean(frontmatter.product) ? <li className="breadcrumb-item">
                                    <span className="text-white">
                                        {frontmatter.product}
                                    </span>
                                </li> : ""}
                                {Boolean(frontmatter.group) ? <li className="breadcrumb-item">
                                    <span className="text-white">
                                        {frontmatter.group}
                                    </span>
                                </li>: ""}
                            </ol>
                        </div>
                        <div className="col-auto d-lg-none ">
                            <div className="navbar-dark">
                                <Button className="navbar-toggler" onClick={() => setOpen(!open)}
                                    aria-controls="sidenavCollapse"
                                    aria-expanded={open}>
                                    <span className="navbar-toggler-icon"></span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <section>
                <Container fluid={true}>
                    <div className="row">
                        <SidebarNav product_group={product_group} location={location} open={open} setOpen={setOpen} />
                        <DocsContent frontmatter={frontmatter} content={body} />
                        <DocsTocNav toc={toc} />
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default DocsLayout