import {Tag} from "@markdoc/markdoc";

export const card = {
    render: 'ClickableCard',
    description: 'Display a clickable card with children',
    // children: ['inline'],
    attributes: {
        title: {
            type: String,
            description: 'The Card Title'
        },
        subtitle: {
            type: String,
            description: 'The Card Subtitle'
        },
        href: {
            type: String,
            description: 'The button link destination'
        },
    }
};