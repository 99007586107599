import * as React from 'react';

export function Row({ children, justify }) {

    const justifyClass = Boolean(justify) ? " justify-content-"+justify : ""

    return (
        <div className={"row" + justifyClass} >
            {children}
        </div>
    )
}